body {
    font-size: 100%;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

a {
    word-wrap: break-word;
}

p {
    color: whitesmoke;
}

.container {
    opacity: 0.92;
    position: relative;
    background-color: whitesmoke;
    border-radius: 5px;
    border: 1px solid grey;
    padding: 18px;
}

.container h2 {
    margin-top: 1rem;
}

.container p {
    color: black;
    border: 0;
}

.centered {
    text-align: center;
    align-items: center;
    justify-content: center;
}

.website-thumbnail {
    width: 100%;
    padding: 1px;
    border: 1px solid #021a40;
    background-color: darkgray;
    border-radius: 4px;
}

/*websites*/
a:active,
a:hover,
a:link,
a:visited {
    text-decoration: none;
}

/* Navbar properties: */
nav.navbar {
    justify-content: space-around;
}

.navbar-brand {
    font-family: 'Courier New', monospace;
    font-size: 1.2em;
}

.nav-item {
    font-family: Arial, sans-serif;
    font-size: 1.2em;
    color: #fff;
    text-decoration: none;
    padding: 15px;
}

.navbar-toggler-icon {
    background-image: url(./images/clipart365828.png)!important;
}

/* Background Image properties: */
img.bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/* Small devices (portrait tablets and large phones, 640px and up) */
@media only screen and (min-width: 640px) {
    img.bg {
        background-size: 0;
        background-repeat: no-repeat;
        background-position: top center;
    }
}

/* Large devices (laptops/desktops, 1024px and up) */
@media only screen and (min-width: 1024px) {
    img.bg {
        background-size: auto;
        background-repeat: no-repeat;
        background-position: top left;
    }
}